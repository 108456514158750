import { StartupAcceleratorComponent } from './startup-accelerator/startup-accelerator.component';
import { SpecialityServicesComponent } from './speciality-services/speciality-services.component';
import { UserExperienceComponent } from './user-experience/user-experience.component';
import { DigitalSolutionsComponent } from './digital-solutions/digital-solutions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductEngineeringComponent } from './product-engineering/product-engineering.component';
import { TalentSolutionsComponent } from './talent-solutions/talent-solutions.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ApplyComponent } from './careers/apply/apply.component';

const routes: Routes = [
  { path: 'product-engineering', component: ProductEngineeringComponent },
  { path: 'talent-solutions', component: TalentSolutionsComponent },
  { path: 'digital-solutions', component: DigitalSolutionsComponent },
  { path: 'user-experience', component: UserExperienceComponent },
  { path: 'speciality-services', component: SpecialityServicesComponent },
  { path: 'startup-accelerator', component: StartupAcceleratorComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'view-job', component: ApplyComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
