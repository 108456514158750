<!-- Technology Section -->
<section
  class="technology-section"
  style="background-image: url(assets/images/background/1.jpg)"
>
  <div
    class="pattern-layer-one"
    style="background-image: url(assets/images/background/pattern-5.png)"
  ></div>
  <div
    class="pattern-layer-two"
    style="background-image: url(assets/images/background/pattern-6.png)"
  ></div>
  <div class="auto-container">
    <div class="row clearfix">
      <!-- Title Column -->
      <div class="title-column col-lg-12 col-md-12 col-sm-12">
        <div class="inner-column">
          <!-- Sec Title -->
          <div class="sec-title light">
            <div class="title">Lorem Ipsum</div>
            <h2>Key Benefits to you</h2>
          </div>
        </div>
      </div>
      <!-- Blocks Column -->
      <div class="blocks-column col-lg-12 col-md-12 col-sm-12">
        <div class="inner-column">
          <div class="row clearfix">
            <!-- Technology Block -->
            <div class="technology-block col-lg-2 col-md-6 col-sm-12">
              <div class="inner-box">
                <a href="#." class="overlay-link"></a>
                <div class="icon-box">
                  <span class="flaticon-coding-2"></span>
                </div>
                <h6>Improved Customer Satisfaction</h6>
              </div>
            </div>

            <!-- Technology Block -->
            <div class="technology-block col-lg-2 col-md-6 col-sm-12">
              <div class="inner-box">
                <a href="#." class="overlay-link"></a>
                <div class="icon-box">
                  <span class="flaticon-coding-2"></span>
                </div>
                <h6>Mature Agility</h6>
              </div>
            </div>

            <!-- Technology Block -->
            <div class="technology-block col-lg-2 col-md-6 col-sm-12">
              <div class="inner-box">
                <a href="#." class="overlay-link"></a>
                <div class="icon-box">
                  <span class="flaticon-coding-2"></span>
                </div>
                <h6>Better Support System</h6>
              </div>
            </div>

            <!-- Technology Block -->
            <div class="technology-block col-lg-2 col-md-6 col-sm-12">
              <div class="inner-box">
                <a href="#." class="overlay-link"></a>
                <div class="icon-box">
                  <span class="flaticon-coding-2"></span>
                </div>
                <h6>Speed & Global Accessibility</h6>
              </div>
            </div>

            <!-- Technology Block -->
            <div class="technology-block col-lg-2 col-md-6 col-sm-12">
              <div class="inner-box">
                <a href="#." class="overlay-link"></a>
                <div class="icon-box">
                  <span class="flaticon-coding-2"></span>
                </div>
                <h6>Latest Tech Stack</h6>
              </div>
            </div>

            <!-- Technology Block -->
            <div class="technology-block col-lg-2 col-md-6 col-sm-12">
              <div class="inner-box">
                <a href="#." class="overlay-link"></a>
                <div class="icon-box">
                  <span class="flaticon-coding-2"></span>
                </div>
                <h6>Scalable and Secure Products</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Technology Section -->
