import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-technology-solutions',
  templateUrl: './technology-solutions.component.html',
  styleUrls: ['./technology-solutions.component.scss'],
})
export class TechnologySolutionsComponent implements OnInit {
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    // this.dataService.clearJob();
  }
}
