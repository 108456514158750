<!-- Start Page Title Area -->
<div class="page-title-area bg-image bg1 style-three jarallax">
  <div class="container">
    <div class="page-title-content">
      <h2>Digital Product Engineering Accelerator</h2>
      <ul>
        <li><a href="#.">Home</a></li>
        <li>Startup Accelerator</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<div class="services-area ptb-100">
  <div class="container">
    <div class="section-title" style="max-width: none">
      <!-- <span class="sub-title"></span> -->
      <h2>Digital Product Engineering Accelerator</h2>
      <p style="max-width: none">
        With our Agile Centric Digital Product Engineering approach, we bring in
        the combination of the latest and smartest technology thus accelerating
        the product development to scale fast and provide meaningful solutions.
        Our Engineering and Delivery Framework; STUPID; Yes we mean it! We
        proudly call Engineering and Delivery Framework as STUPID that
        amalgamates the Technology, Development Velocity and Delivery Excellence
        to deliver the unmet quality, performance and predictability
      </p>
    </div>

    <br />

    <div class="row justify-content-center">
      <div class="container">
        <div class="section-title">
          <h3>Our Approach</h3>
        </div>
        <!-- <img
          src="../../assets/img/startup-accelerator.svg"
          alt="Startup Accelerator"
        /> -->
        <img
          src="../../assets/img/startup-accelerator.png"
          alt="Startup Accelerator"
        />
      </div>
    </div>
  </div>
</div>
