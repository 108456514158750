<!-- Start Page Title Area -->
<div class="page-title-area bg-image bg1 style-three jarallax">
  <div class="container">
    <div class="page-title-content">
      <h2>Speciality Services</h2>
      <ul>
        <li><a href="#.">Home</a></li>
        <li>Speciality Services</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<div class="services-area ptb-100">
  <div class="container">
    <div class="section-title" style="max-width: none">
      <h2>Specialty Services</h2>
      <h4 class="my-2">Withstanding the demands of the new digital era.</h4>
      <p style="max-width: none">
        While business focuses on the key elements of technology; Operations and
        Support are the keys and the integral parts of the technology
        enablement. It is thus paramount to have a well-defined and tactically
        prudent strategy for ever-stronger Operations and Support. Our
        speciality services help you evaluate and assess the current state,
        shape up your desired state, identify key enablers which include
        technology, tools, processes and people. We thus, with an abundant cost
        optimization provide an actionable strategy, roadmap, timeline and plan
        to align appropriate people leading to successfully achieving the
        outlined objectives.
      </p>
    </div>

    <br />

    <div class="row justify-content-center">
      <div class="container">
        <div class="section-title" style="max-width: none">
          <h3>Our Offerings</h3>
        </div>
      </div>
      <div class="col-lg-12 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-7669ff flaticon-cloud-storage"></i>
          <h3><a href="#.">PEO and EOR Services</a></h3>
          <p>
            With our highly process-driven, transparent, innovative and flexible
            Professional Employer Organization or the Employer of Record
            services, we channelize your path of doing your IT and ITES business
            in India with the optimally prized and value-added relationship. We
            thus become your extended arm in India helping you to attract the
            talent, manage the cost and still enjoy your freedom of doing your
            business, your way.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container px-4">
  <div class="section-title">
    <h3>
      Professional Employer Organization ( PEO ) and Employer Of Record ( EOR )
      Services
    </h3>
  </div>
  <!-- <img src="../../assets/img/speciality-services.svg" /> -->
  <img src="../../assets/img/Presentation1_page-0001.png" />
</div>

<div class="pricing-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <!-- <h3>Some Heading Here</h3> -->
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="pricing-item">
          <div class="pricing-header">
            <h3>Silver</h3>
          </div>
          <ul class="features-list">
            <li>Talent Management</li>
            <li>Payroll Processing</li>
            <li class="unactive">Administration Management</li>
            <li class="unactive">Compliance Management</li>
            <li class="unactive">Legal Management</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="pricing-item active">
          <div class="pricing-header">
            <h3>Gold</h3>
          </div>
          <ul class="features-list">
            <li>Talent Management</li>
            <li>Payroll Processing</li>
            <li>Administration Management</li>
            <li>Compliance Management</li>
            <li class="unactive">Legal Management</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="pricing-item">
          <div class="pricing-header">
            <h3>Platinum</h3>
          </div>
          <ul class="features-list">
            <li>Talent Management</li>
            <li>Payroll Processing</li>
            <li>Administration Management</li>
            <li>Compliance Management</li>
            <li>Legal Management</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
