import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TechnologySolutionsComponent } from './technology-solutions/technology-solutions.component';
import { TalentSolutionsComponent } from './talent-solutions/talent-solutions.component';
import { ProductEngineeringComponent } from './product-engineering/product-engineering.component';
import { DigitalSolutionsComponent } from './digital-solutions/digital-solutions.component';
import { UserExperienceComponent } from './user-experience/user-experience.component';
import { SpecialityServicesComponent } from './speciality-services/speciality-services.component';
import { StartupAcceleratorComponent } from './startup-accelerator/startup-accelerator.component';
import { CareersComponent } from './careers/careers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ApplyComponent } from './careers/apply/apply.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    TechnologySolutionsComponent,
    TalentSolutionsComponent,
    ProductEngineeringComponent,
    DigitalSolutionsComponent,
    UserExperienceComponent,
    SpecialityServicesComponent,
    StartupAcceleratorComponent,
    CareersComponent,
    ContactUsComponent,
    ApplyComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
