<section class="apply">
  <div class="container">
    <a routerLink="/careers"
      ><i class="fas fa-arrow-left"></i> Back to all jobs</a
    >
    <h1>{{ job.position_name }}</h1>
    <div class="job-description">
      <h2>Key Roles and Responsibilities :</h2>
      <ul>
        <li *ngFor="let description of jobDescription">
          {{ description }}
        </li>
      </ul>
    </div>
    <div class="job-description">
      <h2>Desired skill & Qualification :</h2>
      <ul>
        <li *ngFor="let skill of skills">{{ skill }}</li>
      </ul>
    </div>
    <h2 class="form-heading">Apply Now</h2>
    <div class="form-container">
      <div class="submit-message" *ngIf="submitted">
        <p>{{ submitMessage }}</p>
      </div>
      <form #f="ngForm" (ngSubmit)="onSubmit(f)" *ngIf="!submitted">
        <div class="input-box">
          <input type="text" name="name" placeholder="Name" ngModel required />
        </div>
        <div class="input-box">
          <input
            type="email"
            name="email"
            placeholder="Email"
            ngModel
            required
            email
          />
        </div>
        <div class="input-box">
          <input
            type="tel"
            name="contact"
            placeholder="Contact"
            ngModel
            required
          />
        </div>
        <div class="input-box">
          <label for="upload-resume">Upload Your Resume</label>
          <input
            id="upload-resume"
            type="file"
            name="resume"
            ngModel
            required
            (change)="handleFileInput($event)"
          />
        </div>
        <div class="submit-btn">
          <button
            type="submit"
            class="default-btn consulting-color"
            [disabled]="!f.valid"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
