<!-- Start Page Title Area -->
<div class="page-title-area bg-image bg1 style-three jarallax">
  <div class="container">
    <div class="page-title-content">
      <h2>Talent Solutions</h2>
      <ul>
        <li><a href="#.">Home</a></li>
        <li>Talent Solutions</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<div class="services-area ptb-100">
  <div class="container">
    <div class="section-title" style="max-width: none">
      <!-- <span class="sub-title"></span> -->
      <h2>Talent Solutions – The New Normal</h2>
      <p style="max-width: none">
        While the organizations were striving hard to be Lean and Agile the
        recent dynamics of a pandemic has changed the world of recruitment and
        hiring. The diluted geographic boundaries have created a need for
        various new normals like; Virtual Organizations (TVOs), Accelerated
        Scale-up Models (ASMs), Optimized Ramp-down Models (ARMs). At WeAgile,
        our HRPS (human Resources and People Services) Experts understand your
        contingent staffing needs and enable you to rapidly scale up or down.
        Your goal; meeting the rapidly changing business landscape and
        optimizing the cost through resource Productivity, Predictability and
        Availability is thus achieved.
      </p>
    </div>

    <br />

    <div class="row justify-content-center">
      <div class="container">
        <div class="section-title" style="max-width: none">
          <h3>Our Offerings</h3>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-7669ff flaticon-cloud-storage"></i>
          <h3><a href="#.">Permanent Placement</a></h3>
          <p>
            We offer the world’s first Permanent Placement Model that reduces
            upfront hiring costs by 70 % by providing a staggered payment option
            and enabling your decision making through powerful analytics
            dashboards.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-ff9f07 flaticon-mobile-app"></i>
          <h3>
            <a href="#.">Contingent Staffing</a>
          </h3>
          <p>
            Supported by our highly experienced Talent Acquisition team, we work
            on end-to-end assignments right from screening to off-boarding of a
            candidate ensuring a quick turn-around and guaranteed value.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-89c111 flaticon-automation"></i>
          <h3>
            <a href="#.">Background Check</a>
          </h3>
          <p>
            Hiring can be catastrophic if not done properly. With our Risk
            Underwriting, Joining Probability Scoring and Pre-Hire Screening
            Models we ensure your hiring to be most appropriate and predictable
            and free from anxieties.<br />&nbsp;
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-2ea7ff flaticon-customer-support"></i>
          <h3>
            <a href="#.">IT Managed Services</a>
          </h3>
          <p>
            Augmented with our highly customizable service models, we enable
            your core teams to focus on the mission-critical priorities and we
            filling in the gaps. We conquer this by building robust SLAs and
            Score Cards and meeting the commitments forever.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="what-we-do-area pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="what-we-do-content">
          <div class="section-title text-center pb-70">
            <h3>and we don't stop there!</h3>
          </div>

          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <!-- <i class="icon flaticon-analytics-1"></i> -->
                <i class="icon flaticon-group"></i>
                <p>
                  At WeAgile, we seamlessly build your contingent teams with
                  flexible engagement models
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-debit-card"></i>
                <p>
                  At WeAgile, we optimize your cash-flow by staggering our
                  hiring fees for 12 months and free replacement for a year
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-startup"></i>
                <p>
                  At WeAgile, our managed services models help your core teams
                  to stay on top in the business and on the business
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-checking"></i>
                <p>
                  At WeAgile our background check is not only providing the
                  validated data but also an intelligent check to predict the
                  joining possibilities of an offered resource
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-6 col-md-12">
        <div class="what-we-do-image">
          <img src="assets/img/what-we-do.jpg" alt="image" />
        </div>
      </div> -->
    </div>
  </div>
</div>
