import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-product-engineering',
  templateUrl: './product-engineering.component.html',
  styleUrls: ['./product-engineering.component.scss'],
})
export class ProductEngineeringComponent implements OnInit {
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.clearJob();
  }
}
