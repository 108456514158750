import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data/data.service';
import { JobService } from '../services/job/job.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
})
export class CareersComponent implements OnInit {
  jobs: any;
  constructor(
    private jobService: JobService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.jobService.getJobs().subscribe((response: any) => {
      this.jobs = response;
    });
    this.dataService.clearJob();
  }

  sendJob(job: any) {
    localStorage.setItem('job', JSON.stringify(job));
  }
}
