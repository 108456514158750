import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { DataService } from 'src/app/services/data/data.service';
import { JobService } from 'src/app/services/job/job.service';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit {
  getJob: any;
  // id!: number;
  job: any;
  resume!: File;
  submitMessage: string = '';
  submitted: boolean = false;
  jobDescription!: string[];
  skills!: string[];

  constructor(
    private jobService: JobService,
    private dataService: DataService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getJob = localStorage.getItem('job');
    this.job = JSON.parse(this.getJob);
    this.jobDescription = this.job.job_description.split('\n');
    this.skills = this.job.skills.split('\n');
  }

  handleFileInput(event: any) {
    this.resume = event.target.files[0];
  }

  onSubmit(form: NgForm) {
    const fd = new FormData();
    fd.append('full_name', form.value.name);
    fd.append('email', form.value.email);
    fd.append('phone_number', form.value.contact);
    fd.append('resume', this.resume, this.resume.name);
    fd.append('job_id', this.job.job_id);

    this.jobService.createApplication(fd).subscribe((response: any) => {
      this.submitMessage = response.msg;
      this.submitted = true;
    });
  }
}
