<!-- Start Page Title Area -->
<div class="page-title-area bg-image bg1 style-three jarallax">
  <div class="container">
    <div class="page-title-content">
      <h2>Careers</h2>
      <h4 style="color: #fff" class="career-heading">
        The best way to predict future is to create AI-ML. <br />
        Come let’s make a reality out of it !
      </h4>
      <ul>
        <li><a href="#.">Home</a></li>
        <li>Careers</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<div class="services-area ptb-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="container">
        <div class="section-title" style="max-width: none">
          <h3>Current Openings</h3>
        </div>
      </div>

      <section class="opening">
        <div class="jobs features-box" *ngFor="let job of jobs">
          <div class="job-info">
            <h2>{{ job.position_name }}</h2>
            <p style="margin-bottom: 0">Experience : {{ job.experience }}</p>
            <p>{{ job.job_summary }}</p>
          </div>
          <div class="apply-btn">
            <a
              routerLink="/view-job"
              (click)="sendJob(job)"
              class="default-btn consulting-color"
              >Apply Now <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
