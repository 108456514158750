<div class="consulting-banner-area">
  <div class="container">
    <div class="consulting-banner-content">
      <!-- <h1>We Are Always As Your Service For Any Business Consulting</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p> -->
      <a routerLink="/contact" class="default-btn consulting-color">
        Contact Us <i class="fas fa-chevron-right"></i>
      </a>
    </div>
  </div>
</div>

<!-- Start Speciality Service Area -->
<div class="features-area bg-1b2232 ptb-70">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="single-features-item color-white">
          <i class="icon flaticon-analytics-1"></i>
          <h3>Advanced <br />Analytics</h3>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="single-features-item color-white">
          <i class="icon flaticon-innovation-1"></i>
          <h3>Business <br />Innovation</h3>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="single-features-item color-white">
          <i class="icon flaticon-growth-1"></i>
          <h3>Growth <br />Consulting</h3>
        </div>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6">
        <div class="single-features-item color-white">
          <i class="icon flaticon-consultation"></i>
          <h3>Technology <br />Consulting</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Speciality Service Area -->

<!-- Start Who We Are Area -->
<div class="who-we-are-area pt-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="who-we-are-content">
          <span class="sub-title">WHO WE ARE</span>
          <h2>We help enterprises to thrive as modern digital businesses.</h2>
          <p>
            We are a leading technology firm that integrates strategy, design
            and software engineering to enable enterprises and technology
            disruptors across the globe to thrive as modern digital businesses.
            <br />
            Ongoing digital disruption is challenging enterprises to keep pace
            with the accelerating rate of technological change. This is where
            WeAgile can help. We leverage our vast experience to improve our
            clients’ ability to respond to change; utilize data assets to unlock
            new sources of value; create adaptable technology platforms that
            move with business strategies; and rapidly design, deliver and
            evolve exceptional digital products and experiences at scale.
          </p>
          <a href="#." class="default-btn consulting-color">
            Read More <i class="fas fa-chevron-right"></i
          ></a>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="who-we-are-image">
          <img src="assets/img/who-we-are.jpg" alt="image" />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Who We Are Area -->

<!-- Start Services Area -->
<div class="services-area bg-f9f9f9 pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <span style="color: #48dbfb" class="sub-title it-agency-color">
        WHAT WE DO
      </span>
      <h2>Services that can fuel your enterprise</h2>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-6">
        <div class="single-it-services-box bg-6d5fff">
          <i class="icon flaticon-web-development"></i>
          <h3><a href="services-details-1.html"> Product Engineering </a></h3>
          <p>
            With our Matured Agility, Tested and Trusted Processes, In-depth
            Technology Knowledge and Rich Domain Expertise we help you realize
            the Product Engineering and Technology Upgrade benefits while
            mitigating the disruption.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="single-it-services-box bg-ff9f07">
          <i class="icon flaticon-development-3"></i>
          <h3><a href="services-details-1.html"> Digital Solutions </a></h3>
          <p>
            With the unmatched skills and years of experience of our team, we
            deliver new digital experiences, revenue streams and business models
            that meets your expectations, accelerate your growth and navigates
            your business in this era of technological evolution.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="single-it-services-box bg-89c111">
          <i class="icon flaticon-digital-marketing-1"></i>
          <h3><a href="services-details-1.html"> Talent Solutions </a></h3>
          <p>
            While the organizations were striving hard to be Lean and Agile the
            recent dynamics of pandemic has changed the world of recruitment and
            hiring. Our Human Resources and People Services Experts understand
            your contingent staffing needs and enable you to rapidly scale up or
            down.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="single-it-services-box bg-ca83ff">
          <i class="icon flaticon-analytics"></i>
          <h3><a href="services-details-1.html"> User Experience </a></h3>
          <p>
            The new world demands the fulfilling of the data-driven and the user
            centric design approach that create the brand foot print and the
            success strategy. With our brilliant team of User experience
            Designers, we ensure that every element of the interface should be
            designed keeping the end user in mind.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="single-it-services-box bg-ff5f5f">
          <i class="icon flaticon-solution-1"></i>
          <h3><a href="services-details-1.html"> Speciality Services </a></h3>
          <p>
            Our specialty services help you evaluate and assess the current
            state, shape up your desired state, identify key enablers which
            include technology, tools, processes and people, thus allowing you
            to withstand the demands of the new digital era.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6">
        <div class="single-it-services-box bg-2ea7ff">
          <i class="icon flaticon-consulting-1"></i>
          <h3><a href="services-details-1.html"> Startup Accelerator </a></h3>
          <p>
            With our Agile Centric Digital Product Engineering approach, we
            bring in the combination of the latest and smartest technology thus
            accelerating the product development to scale fast and provide
            meaningful solutions.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Services Area -->

<!-- Start Divers Area -->
<div class="services-area bg-f9f9f9 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Our Drivers of Success</h2>
    </div>

    <div class="row justify-content-center">
      <div class="funfacts-area pb-70">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-fd5444 flaticon-file"></i>
                <h5>Mature Agility</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-3251ad flaticon-rating-2"></i>
                <h5>Enhanced Flexibility</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-ff6720 flaticon-verified"></i>
                <h5>Customer Satisfaction</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-62bd5e flaticon-data"></i>
                <h5>Latest Tech Stack</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-ff6720 flaticon-hacker"></i>
                <h5>Scalable and Secure Products</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-62bd5e flaticon-fast-time"></i>
                <h5>Speed and Global accessibility</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 col-6">
              <div class="single-funfacts-box bg-white">
                <i class="icon color-ff6720 flaticon-trophy"></i>
                <h5>Better Support Ecosystem</h5>
                <img
                  src="assets/img/shape/border2.png"
                  alt="image"
                  class="shape"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-center">
      <div class="text-center">
        <p style="font-size: 16px" class="col-md-8 offset-md-2">
          With our expert team members, we continue to change the way
          conventional businesses are perceived and operated, keeping pace with
          the rapidly evolving digital world.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- End Divers Area -->

<!-- Start Services Area -->
<!-- <div class="services-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <span class="sub-title consulting-color">OUR SERVICES</span>
      <h2>Consulting Services</h2>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="services-item">
          <img src="assets/img/services/services-img13.jpg" alt="image" />
          <h3>Sales & Marketing</h3>
          <div class="hover-content">
            <h3><a href="#.">Sales & Marketing</a></h3>
            <p>
              Lorem ipsum dolor sit amet, cons e ctetur adipiscing elit, sed do
              eiu smod tempor incididunt ut labore et dolore magna.
            </p>
            <a href="#." class="link-btn"
              >Read More <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="services-item">
          <img src="assets/img/services/services-img14.jpg" alt="image" />
          <h3>Cost Transformation</h3>
          <div class="hover-content">
            <h3><a href="#.">Cost Transformation</a></h3>
            <p>
              Lorem ipsum dolor sit amet, cons e ctetur adipiscing elit, sed do
              eiu smod tempor incididunt ut labore et dolore magna.
            </p>
            <a href="#." class="link-btn"
              >Read More <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="services-item">
          <img src="assets/img/services/services-img15.jpg" alt="image" />
          <h3>Sustainability</h3>
          <div class="hover-content">
            <h3><a href="#.">Sustainability</a></h3>
            <p>
              Lorem ipsum dolor sit amet, cons e ctetur adipiscing elit, sed do
              eiu smod tempor incididunt ut labore et dolore magna.
            </p>
            <a href="#." class="link-btn"
              >Read More <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="services-item">
          <img src="assets/img/services/services-img16.jpg" alt="image" />
          <h3>Strategy</h3>
          <div class="hover-content">
            <h3><a href="#.">Strategy</a></h3>
            <p>
              Lorem ipsum dolor sit amet, cons e ctetur adipiscing elit, sed do
              eiu smod tempor incididunt ut labore et dolore magna.
            </p>
            <a href="#." class="link-btn"
              >Read More <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="services-item">
          <img src="assets/img/services/services-img17.jpg" alt="image" />
          <h3>Customer Experience</h3>
          <div class="hover-content">
            <h3><a href="#.">Customer Experience</a></h3>
            <p>
              Lorem ipsum dolor sit amet, cons e ctetur adipiscing elit, sed do
              eiu smod tempor incididunt ut labore et dolore magna.
            </p>
            <a href="#." class="link-btn"
              >Read More <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="services-item">
          <img src="assets/img/services/services-img18.jpg" alt="image" />
          <h3>Profit Planning</h3>
          <div class="hover-content">
            <h3><a href="#.">Profit Planning</a></h3>
            <p>
              Lorem ipsum dolor sit amet, cons e ctetur adipiscing elit, sed do
              eiu smod tempor incididunt ut labore et dolore magna.
            </p>
            <a href="#." class="link-btn"
              >Read More <i class="fas fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- End Services Area -->

<!-- Start Funfacts Area -->
<div class="funfacts-area bg-image pt-100 pb-70">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-sm-6 col-md-3 col-6">
        <div class="single-funfacts-box consulting-color text-center">
          <h3>100+</h3>
          <span class="sub-title">Clients Served Globally</span>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-3 col-6">
        <div class="single-funfacts-box consulting-color text-center">
          <h3>150+</h3>
          <span class="sub-title">Successful Projects</span>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-3 col-6">
        <div class="single-funfacts-box consulting-color text-center">
          <h3>6</h3>
          <span class="sub-title">Years in Business</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Funfacts Area -->

<!-- Start What We Do Area -->
<div class="what-we-do-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 col-md-12">
        <div class="what-we-do-content">
          <div class="section-title text-center">
            <span class="sub-title consulting-color">WHY WEAGILE?</span>
            <h2>Passion in veins</h2>
            <p>
              You have the vision for a stunning digital experience. We’re the
              software design and engineering team that can bring it to life.
            </p>
          </div>

          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-project-management-1"></i>
                <h3>6+ Years of Experience</h3>
                <p>in product Modernization and Technology Solutions</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-project-management-1"></i>
                <h3>Highly Scalable and Flexible</h3>
                <p>on-premises and off-shore resource engagement model</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-project-management-1"></i>
                <h3>Best-in-class velocity</h3>
                <p>to scale from Prototype to Production product stability</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
              <div class="single-what-we-do-box">
                <i class="icon flaticon-project-management-1"></i>
                <h3>Energetic and Enthusiastic</h3>
                <p>
                  workforce with expertise in modern and popular technologies
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-6 col-md-12">
        <div class="what-we-do-image">
          <img src="assets/img/what-we-do.jpg" alt="image" />
        </div>
      </div> -->
    </div>
  </div>
</div>
<!-- End What We Do Area -->
