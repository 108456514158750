import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../services/data/data.service';
import { JobService } from '../services/job/job.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  constructor(
    private jobService: JobService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.dataService.clearJob();
  }

  onSubmit(form: NgForm) {
    const data = {
      name: form.value.name,
      email: form.value.email,
      contact: form.value.contact,
      message: form.value.message,
    };

    this.jobService.contactUs(data).subscribe((response: any) => {
      console.log(response);
      form.reset();
      alert('Your response is submitted');
    });
  }
}
