<!-- Start Page Title Area -->
<div class="page-title-area bg-image bg1 style-three jarallax">
  <div class="container">
    <div class="page-title-content">
      <h2>Contact Us</h2>
      <ul>
        <li><a href="#.">Home</a></li>
        <li>Contact Us</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<div class="services-area ptb-100">
  <div class="container">
    <div class="section-title" style="max-width: none">
      <h2>Contact Us</h2>
      <span
        class="sub-title"
        style="max-width: none; margin-top: 1rem; color: #0d47a1"
      >
        Get in touch to take the next step with WeAgile. <br />
        Find out more about how WeAgile can help your business.
      </span>

      <div class="form-and-map-wrapper">
        <div class="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.347548539726!2d73.77521301481381!3d18.558363587387042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfc51fa5ec7b%3A0x3ba559db0adfd83a!2sWeAgile%20Software%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1655803168018!5m2!1sen!2sin"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div class="direction-btn">
            <a
              class="default-btn consulting-color"
              href="https://www.google.com/maps/dir//WeAgile+Software+Solutions+Pvt+Ltd+S.No.+32%2F1A%2F1%2F30+to+38+%26+54+Pan+Card+Club+Rd,+Baner+Pune,+Maharashtra+411045/@18.5583636,73.7774017,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bfc51fa5ec7b:0x3ba559db0adfd83a!2m2!1d73.7774017!2d18.5583636"
              target="_blank"
            >
              Get Direction
            </a>
          </div>
        </div>
        <div class="form-container">
          <form class="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div class="input-box">
              <input
                type="text"
                name="name"
                placeholder="Full name"
                ngModel
                required
              />
            </div>
            <div class="input-box">
              <input
                type="email"
                name="email"
                placeholder="Email"
                ngModel
                email
                required=""
              />
            </div>
            <div class="input-box">
              <input
                type="tel"
                name="contact"
                placeholder="Contact"
                ngModel
                [minLength]="10"
                required
              />
            </div>
            <div class="input-box">
              <textarea
                cols="30"
                rows="8"
                name="message"
                placeholder="Message"
                ngModel
                required
              ></textarea>
            </div>
            <div class="submit-btn">
              <button
                type="submit"
                class="default-btn consulting-color"
                [disabled]="!f.valid"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
