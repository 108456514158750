import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}

  clearJob() {
    localStorage.removeItem('job');
  }
}
