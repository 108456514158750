<!-- Start Call To Action Area -->
<div class="call-to-action-area bg-image ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-12">
        <div class="call-to-action-content">
          <h2>Get in touch to take the next step with WeAgile</h2>
          <p style="color: #fff">
            Find out more about how WeAgile can help your business
          </p>
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="call-to-action-btn">
          <a href="#." class="default-btn consulting-color"
            >Contact Us <i class="fas fa-chevron-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Call To Action Area -->

<div class="go-top consulting-color"><i class="fas fa-chevron-up"></i></div>

<!-- Start Footer Area -->
<footer class="footer-area consulting-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3>Contact Details</h3>

          <ul class="footer-contact">
            <li>
              <i class="fas fa-map-marker-alt"></i> 6th floor, 38 & 54,
              Westport, Pan Card Club Road,
              <br />
              Baner, Pune, Maharashtra Pin-411045
            </li>
            <li>
              <i class="fas fa-phone-alt"></i>
              <a href="tel:+919370908780">+91 9370908780</a>
            </li>
            <li>
              <i class="fas fa-envelope"></i>
              <a href="mailto:business.excellence@weagile.net"
                >business.excellence@weagile.net</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget pl-4">
          <h3>Important Links</h3>

          <ul class="info-links">
            <li>
              <a routerLink="product-engineering"> Product Engineering </a>
            </li>

            <li>
              <a routerLink="digital-solutions"> Digital Solutions </a>
            </li>

            <li>
              <a routerLink="talent-solutions"> Talent Solutions </a>
            </li>

            <li>
              <a routerLink="careers"> Careers </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget">
          <h3></h3>
          <br />

          <ul class="info-links">
            <li>
              <a routerLink="user-experience"> User Experience </a>
            </li>

            <li>
              <a routerLink="speciality-services"> Speciality Services </a>
            </li>

            <li>
              <a routerLink="startup-accelerator"> Startup Accelerator </a>
            </li>

            <li>
              <a routerLink="contact"> Contact Us </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright-area">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-12">
          <div class="text-start">
            <a href="#." class="logo">
              <img src="assets/img/logo.png" alt="image" />
            </a>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <p>Copyright @2021 WeAgile</p>
        </div>

        <div class="col-lg-3 col-md-12">
          <ul class="social-links">
            <!-- <li>
              <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
            </li> -->
            <li>
              <a
                href="https://www.linkedin.com/company/weagile-software-solution-pvt-ltd/"
                target="_blank"
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="shape17">
    <img src="assets/img/shape/shape17.png" alt="image" />
  </div>
</footer>
<!-- End Footer Area -->
