import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-startup-accelerator',
  templateUrl: './startup-accelerator.component.html',
  styleUrls: ['./startup-accelerator.component.scss'],
})
export class StartupAcceleratorComponent implements OnInit {
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.clearJob();
  }
}
