<!-- Start Page Title Area -->
<div class="page-title-area bg-image bg1 style-three jarallax">
  <div class="container">
    <div class="page-title-content">
      <h2>User Experience</h2>
      <ul>
        <li><a href="#.">Home</a></li>
        <li>User Experience</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<div class="services-area ptb-100">
  <div class="container">
    <div class="section-title" style="max-width: none">
      <!-- <span class="sub-title"></span> -->
      <h2>User Experience - Building the Brand</h2>
      <p style="max-width: none">
        While your domain is the mothership of your towering success;
        irrespective of your domain, whenever you deliver a product, service,
        web or a mobile application or any other interface, the user experience
        ensures your ROI, customer enlightenment and unforgettable experience.
        Indeed, an enterprise cannot afford to ignore the impact of user
        experience! The new world demands the fulfilment of the data-driven and
        user-centric design approach that create the brand footprint and the
        success strategy.
      </p>
    </div>

    <br />

    <div class="row justify-content-center">
      <div class="container">
        <div class="section-title" style="max-width: none">
          <h3>Our Offerings</h3>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-7669ff flaticon-cloud-storage"></i>
          <h3><a href="#.">Prototyping</a></h3>
          <p>
            With our innovation accounting approach, we in multifold, accelerate
            your time to market. Partnering with you through our power-packed
            and result in oriented Design sprints we convert your idea into a
            product that you can feel, sense and measure.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-ff9f07 flaticon-mobile-app"></i>
          <h3>
            <a href="#.">Interface UX</a>
          </h3>
          <p>
            May it be your Mobile, Web, E-commerce or wearable device interface,
            we create a smooth, adaptable, intuitive interface. Less is more
            thought embedded with our creativity results in magnified
            communication with high stickiness of user.<br />&nbsp;
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-89c111 flaticon-automation"></i>
          <h3>
            <a href="#.">UX & Behavioural Design Research</a>
          </h3>
          <p>
            Behavioural Design makes it easy to do ‘The Right Thing'. It is a
            research-driven framework to build Positive Reinforcement while
            keeping a user in an intuitive state and creating an illusion of
            never-ending information.
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="features-box">
          <i class="icon bg-2ea7ff flaticon-customer-support"></i>
          <h3>
            <a href="#.">Product Interfacing</a>
          </h3>
          <p>
            Simple interface, clear messaging, highlighting the user needs,
            providing the optimum details, makes the most complex products
            highest usable. After all, clutter is NOT what sells but the ease of
            use.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
