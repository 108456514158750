import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getJobs() {
    return this.http.get(this.baseUrl + 'alljobs/');
  }

  getJobDetails(id: number) {
    return this.http.get(this.baseUrl + `getjobdetails/${id}`);
  }

  createApplication(data: any) {
    return this.http.post(this.baseUrl + 'createapplication/', data);
  }

  contactUs(data: any) {
    return this.http.post(this.baseUrl + 'contactus/', data);
  }
}
