import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data/data.service';

@Component({
  selector: 'app-speciality-services',
  templateUrl: './speciality-services.component.html',
  styleUrls: ['./speciality-services.component.scss'],
})
export class SpecialityServicesComponent implements OnInit {
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.clearJob();
  }
}
