<div class="page-wrapper">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

<!--Scroll to top-->
<div class="scroll-to-top scroll-to-target" data-target="html">
  <span class="fa fa-arrow-up"></span>
</div>
